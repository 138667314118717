.form-control {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.form-control input {
	border: none;
	outline: none;
	padding: 10px;
	font-size: 1rem;
	background-color: #edf2f469;
	transition: all ease 0.2s;
	box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 1px 0px inset, rgba(0, 0, 0, 0.06) -1px -1px 1px 0px inset;
	height: 100%;
    border-radius: 5px;
}

.form-control input:focus {
	background-color: rgb(237, 242, 244);
	box-shadow: rgba(150, 150, 150, 0.2) 1px 1px 1px 0px inset, rgba(150, 150, 150, 0.20) -1px -1px 1px 0px inset;
}