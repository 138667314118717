.welcome {
    text-align: center;
}

.buttons-wrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.modal-header {
    background-color: #fff;
    padding: 15px;
}

.modal-header h3 {
    margin: 0;
}