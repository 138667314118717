@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap");

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	font-size: 1rem !important;
    background-color: #3d405b;
}

* {
	font-family: "Roboto", sans-serif;
    box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 1100px) {
    .todo-card {
        width: 100%;
        flex: none !important;
    }
}