.modal {
	position: fixed;
	left: 50%;
	top: 50px;
	transform: translateX(-50%);
	z-index: 999;
	background-color: #fff;
	padding: 20px;
	width: 90%;
	max-width: 780px;
    border-radius: 5px;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-text {
	margin-bottom: 10px;
}

.header-text span {
	font-weight: bold;
	font-size: 1.2rem;
}

.header-close:hover {
	cursor: pointer;
}
