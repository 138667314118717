.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spinning 0.7s cubic-bezier(0.2, 0.4, 0.6, 0.8) infinite;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}