@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap);
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	font-size: 1rem !important;
    background-color: #3d405b;
}

* {
	font-family: "Roboto", sans-serif;
    box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 1100px) {
    .todo-card {
        width: 100%;
        -webkit-flex: none !important;
                flex: none !important;
    }
}
.Loading_loading-wrapper__1vrTk {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-animation: Loading_spinning__2l-5e 0.7s cubic-bezier(0.2, 0.4, 0.6, 0.8) infinite;
            animation: Loading_spinning__2l-5e 0.7s cubic-bezier(0.2, 0.4, 0.6, 0.8) infinite;
}

@-webkit-keyframes Loading_spinning__2l-5e {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes Loading_spinning__2l-5e {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.Button_button__6i__M {
	border: none;
	height: 40px;
	min-width: 40px;
	border-width: 1px;
	border-style: solid;
    color: #2a9d8f;
    border-color: #2a9d8f;
    background-color: transparent;
    transition: all ease 0.3s;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;
}

.Button_button__6i__M:not([disabled]):hover {
	cursor: pointer;
    background-color: #2a9d8f;
    color: #fff;
}

.Button_button__6i__M:not([disabled]):active {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.Button_button__6i__M:disabled {
	cursor: not-allowed;
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
}

.Button_button__6i__M span {
    padding: 0 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

/* RED COLOR */
.Button_red__1nuAR {
	color: #e63946;
    border-color: #e63946;
}

.Button_red__1nuAR:not([disabled]):hover {
	background-color: #e63946;
    color: #fff;
}

/* BLUE COLOR */
.Button_blue__cfYcj {
	color: #48cae4;
    border-color: #48cae4;
}

.Button_blue__cfYcj:not([disabled]):hover {
	background-color: #48cae4;
    color: #fff;
}

/* YELLOW COLOR */
.Button_yellow__1PwaN {
	color: #fca311;
    border-color: #fca311;
}

.Button_yellow__1PwaN:not([disabled]):hover {
	background-color: #fca311;
    color: #fff;
}
.Card_card__3lXvb {
    padding: 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #000;
}
.Input_form-control__3dnCe {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
}

.Input_form-control__3dnCe input {
	border: none;
	outline: none;
	padding: 10px;
	font-size: 1rem;
	background-color: #edf2f469;
	transition: all ease 0.2s;
	box-shadow: rgba(0, 0, 0, 0.06) 1px 1px 1px 0px inset, rgba(0, 0, 0, 0.06) -1px -1px 1px 0px inset;
	height: 100%;
    border-radius: 5px;
}

.Input_form-control__3dnCe input:focus {
	background-color: rgb(237, 242, 244);
	box-shadow: rgba(150, 150, 150, 0.2) 1px 1px 1px 0px inset, rgba(150, 150, 150, 0.20) -1px -1px 1px 0px inset;
}
.AddTodoItem_add-new-form__2KQKz {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    grid-gap: 10px;
    gap: 10px;
}
.TodoItem_item-wrapper__WnNRO {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.TodoItem_edit-form__1NUHW {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	        align-items: stretch;
	grid-gap: 10px;
	gap: 10px;
}

.TodoItem_task-action__3xBhd {
	display: -webkit-flex;
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

.TodoItem_task-text__2QCmc {
	padding: 10px 0;
    font-weight: 300;
}

@media screen and (max-width: 720px) {
	.TodoItem_item-wrapper__WnNRO {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: stretch;
		        align-items: stretch;
	}

	.TodoItem_task-action__3xBhd {
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		-webkit-flex: 1 1;
		        flex: 1 1;
	}
}

.Welcome_welcome__1C2fV {
    text-align: center;
}

.Welcome_buttons-wrapper__LRkhT {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    -webkit-justify-content: center;
            justify-content: center;
}

.Welcome_modal-header__ewITL {
    background-color: #fff;
    padding: 15px;
}

.Welcome_modal-header__ewITL h3 {
    margin: 0;
}
.Backdrop_backdrop__vrX3z {
    position: fixed;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
}
.Modal_modal__1GJfr {
	position: fixed;
	left: 50%;
	top: 50px;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 999;
	background-color: #fff;
	padding: 20px;
	width: 90%;
	max-width: 780px;
    border-radius: 5px;
}

.Modal_modal-header__2SeF7 {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.Modal_header-text__169IV {
	margin-bottom: 10px;
}

.Modal_header-text__169IV span {
	font-weight: bold;
	font-size: 1.2rem;
}

.Modal_header-close__2nrvJ:hover {
	cursor: pointer;
}

.InitTodoList_init-form__1_ce_ {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}
.Layout_layout__3poN2 {
	width: 1200px;
	max-width: 90%;
	margin: auto;
}

